import React from "react"
import Layout from "../components/Layout"

const AvisoLegal = () => {
  return (
    <Layout>
      <div className="container">
        <div className="text-component margin-y-lg">
          <h2 className="text-md">
            Aviso Legal e información sobre las condiciones de uso del sitio web
          </h2>
          <p>Datos identificativos del titular del sitio web</p>
          <p>
            En cumplimiento del deber de información estipulado en el artí­culo
            10 de la Ley 34/2002 de 11 de julio de Servicios de la Sociedad de
            la Información y de Comercio Electrónico, RIEGOS AZUER, S.L. y en
            calidad de titular del web site www.riegosazuer.com, procede a
            comunicarles los datos identificativos exigidos por la referida
            norma:
          </p>
          <p>Denominación social: RIEGOS AZUER, S.L.</p>
          <p>
            Domicilio social: Calle XV, Parc. 110, 13200, Manzanares, Ciudad
            Real.
          </p>
          <p>CIF: B13051990</p>
          <p>
            Dirección de correo electrónico:{" "}
            <a href="mailto:riegosazuer@riegosazuer.com">
              riegosazuer@riegosazuer.com
            </a>
          </p>
          <p>
            Datos de inscripción en el Registro Mercantil: Tomo 492, Folio 103,
            Hoja CR 5750, Inscripción 9º.
          </p>
          <p>
            La presente información conforma y regula las condiciones de uso,
            las limitaciones de responsabilidad y las obligaciones que, los
            usuarios de la página Web que se publica bajo el nombre de dominio
            www.riegosazuer.com, asumen y se comprometen a respetar.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default AvisoLegal
